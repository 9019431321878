import logo from '../Pictures/logo.jpg'
// import getTogether from '../Pictures/Gallery/Random.jpg'
// import parties from '../Pictures/Gallery/ChristmasParty.jpg'
import christmasFlyer from '../Pictures/Tumbnails/christmasFlyer.jpg'
import bbqFlyer from '../Pictures/Tumbnails/bbq2024.jpg'
import jobspin from '../Pictures/Tumbnails/Jobspin-banner.png'
import { message } from 'antd'
import { Link } from 'react-router-dom'

export const Events = [
    /* Template:
    {
        id: 1,
        image: logo,
        event: 'Sports event',
        date: 'March 11 2023',
        time: 'TBA'
        sort: new Date('March 11 2023'),
        location:'Hala',
        summary: 'Badminton',
        flyer:parties,
        map: 'https://goo.gl/maps/RjAn9QZjRHqD5GUy9',
        form:'url'
        
    }*/
    {
        id: 1,
        image: logo,
        event: 'Summer grill party',
        date: 'August 12, 2023',
        time: '13:00',
        sort: new Date('August 12 2023'),
        location: 'Na Parukářce 2771/1, 130 00 Praha 3-Žižkov',
        map: 'https://goo.gl/maps/WwBfhpQbVhSWEFcz5',
        gallery:true
        
    },

    {
        id: 2,
        image: logo,
        event: 'Informal get together',
        time: '19:00',
        date: 'September 23, 2023',
        sort: new Date('September 23 2023'),
        location: 'Potrefená Husa Hybernská, Dlážděná , 1003/7, 110 00, Praha 1',
        map: 'https://goo.gl/maps/fWMiBhZafAkXiPwD9',
        gallery:true

    },

    {
        id: 3,
        image: logo,
        event: 'General Meeting',
        time: '18:00',
        date: 'October 14, 2023',
        sort: new Date('October 14 2023'),
        location: 'Zoom',
        gallery:false

    },

    {
        id: 4,
        image: logo,
        event: 'General Meeting',
        date: 'December 9, 2023',
        time: '18:00',
        sort: new Date('December 9 2023'),
        location: 'Zoom',

    },

    {
        id: 5,
        image: christmasFlyer,
        event: 'Year end get together',
        date: 'December 25, 2023',
        time: '15:00',
        sort: new Date('December 25 2023'),
        location: 'Vocelova 602/3 120 00 Praha 2',
        flyer: christmasFlyer,
        //form:'https://forms.gle/t6i6Q7XCcPjX2cKm9',
    },


    /*2024*****************************************************************************/

    {
        id: 6,
        image: logo,
        event: 'Ghana Independence Day Celebration activities',
        date: 'March 9 2024',
        time: '11: 00',
        sort: new Date('March 9 2024'),
        location:'Hamr Sports center Braník',
        map: 'https://maps.app.goo.gl/bQLRGHgMBCpcXgoa7'
    },

    {
        id: 7,
        image: logo,
        event: 'General Meeting',
        date: 'March 16 2024',
        time: '18:00',
        sort: new Date('March 16 2024'),
        location:'Via Zoom',
    },

    {
        id: 8,
        image: logo,
        event: 'Informal get together',
        date: 'April 20 2024',
        time: '18:00',
        sort: new Date('April 20 2024'),
        location:'TBA',
    },

    {
        id: 9,
        image: logo,
        event: 'General Meeting',
        date: 'June 22 2024',
        time: '18:00',
        sort: new Date('June 22 2024'),
        location:'TBA',
    },

    {
        id: 10,
        image: logo,
        event: 'General Meeting',
        date: 'July 14 2024',
        time: '18:00',
        sort: new Date('July 14 2024'),
        location:'Zoom',
    },

    {
        id: 11,
        image: logo,
        event: 'Health Seminar',
        date: 'July 20 2024',
        time: 'TBA',
        sort: new Date('July 20 2024'),
        location:'TBA',
    },

    {
        id: 12,
        image: bbqFlyer,
        event: 'Summer grill Party (Prague)',
        date: 'August 17 2024',
        time: '14:00',
        sort: new Date('August 17 2024'),
        location:'Na Parukářice 2771/1',
        map: 'https://maps.app.goo.gl/Gdk3291NfFHspwHR8'
    },

    {
        id: 13,
        image: logo,
        event: 'General Meeting',
        date: 'September 21 2024',
        time: '18:00',
        sort: new Date('September 21 2024'),
        location:'TBA',
    },

    {
        id: 14,
        image: logo,
        event: 'Informal get together',
        date: 'October 26 2024',
        time: '18:00',
        sort: new Date('October 26 2024'),
        location:'TBA',
    },
    {
        id: 15,
        image: logo,
        event: 'Bowling',
        date: 'December 8 2024',
        time: '16:00',
        sort: new Date('December 8 2024'),
        location:'V Celnici 10 Praha 1',
        map: 'https://maps.app.goo.gl/ECuj8U9kXUea7Ciq5',
    },

    {
        id: 16,
        image: logo,
        event: 'General Meeting',
        date: 'December 14 2024',
        time: '18:00',
        sort: new Date('December 14 2024'),
        location:'TBA',
    },

    /*2025*****************************************************************************/

    {
        id: 17,
        image: 'https://icpraha.com/wp-content/uploads/2025/01/3-1.png',
        event: 'Seminar: Czech school system: elementary school and kindergarten ',
        summary: <h3 className='summary-3' style={{textAlign:'center'}}>Understanding the Czech  school system or finding a placement for your child as a foreigner could be  cumbersome. If you want to learn more then register for the seminar:<br/> <br/> {new Date()<new Date('February 18 2025')?<Link  style={{border:'1px solid', color:'darkgoldenrod', padding:'10px', borderRadius:'5px', }} target='_blank' to = {'https://docs.google.com/forms/d/e/1FAIpQLSdO4f2cc7la-3M_i82idBpNlQ40GMpIsohoMt6nT8R6kGrK6A/viewform'}>Click here to learn more and register</Link>:''}</h3>,
        date: 'February  18 2025',
        time: '18:00', 
        sort: new Date('February  18 2025'),
        location:'Zoom',
    },
    {
        id:18,
        image: logo,
        event: 'Webinar Tax Declaration in the Czech Republic: How to File Correctly in 2025',
        date: 'March 19, 2025',
        time: '16:00',
        sort: new Date('March 19 2025'),
        location:'Zoom',
    },


    {
        id: 19,
        image: logo,
        event: 'General Meeting',
        date: 'March 29 2025',
        time: '18:00',
        sort: new Date('March 29 2025'),
        location:'TBA',
    }, 
    {
        id: 20,
        image: jobspin,
        event: 'Jobspin: Job and Relocation Fair',
        date: 'April 1 2025',
        time: '10:00 - 18:30',
        sort: new Date('April 1 2025'),
        location:'Prague Congress Centre',
        summary: <Link to={'https://www.jobspin.cz/2025-visitor-registration-jobspin-job-and-relocation-fair-in-prague/'} style={{border:'1px solid', color:'darkgoldenrod', padding:'10px', borderRadius:'5px', }} target='_blank'>Register Here </Link>,
        map:'https://maps.app.goo.gl/affRfS1YYvqgxnUf8'

    }, 

    {
        id: 21,
        image: logo,
        event: 'General Meeting',
        date: 'June 28 2025',
        time: '18:00',
        sort: new Date('June 28 2025'),
        location:'TBA',
    },
    {
        id: 22,
        image: logo,
        event: 'General Meeting',
        date: 'October 18',
        time: '18:00',
        sort: new Date('October 18 2025'),
        location:'TBA',
    },




    


    
]


